'use client';

import { useState, FormEvent, useEffect } from 'react';
import { TextField, FormControlLabel, Checkbox } from '@mui/material';
import tokens from '@verifime/design-tokens';
import EmailInputWithContinueButton, {
  TEmailInputWithContinueButtonProps,
} from './EmailInputWithContinueButton';

export type TSignUpCodeConfig = {
  signUpCode?: string;
  allowChange?: boolean;
  onSignUpCodeChange?: (signUpCode: string) => void;
};

export default function SignUp({
  signUpCodeConfig,
  onSubmit,
  ...props
}: {
  signUpCodeConfig?: TSignUpCodeConfig;
  onSubmit: ({
    payload,
    event,
  }: {
    payload: { email: string; signUpCode?: string };
    event: FormEvent<HTMLFormElement>;
  }) => Promise<void>;
} & Readonly<Omit<TEmailInputWithContinueButtonProps, 'additionalComponent' | 'onSubmit'>>) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasSignUpCode, setHasSignUpCode] = useState(!!signUpCodeConfig?.signUpCode);
  const [signUpCode, setSignUpCode] = useState(signUpCodeConfig?.signUpCode || '');

  const allowSignUpCodeChange =
    signUpCodeConfig?.allowChange === undefined ? true : signUpCodeConfig.allowChange;

  useEffect(() => {
    setHasSignUpCode(!!signUpCodeConfig?.signUpCode);
    setSignUpCode(signUpCodeConfig?.signUpCode || '');
  }, [signUpCodeConfig]);

  const onSubmitHandler = ({
    email,
    event,
  }: {
    email: string;
    event: FormEvent<HTMLFormElement>;
  }) => {
    setIsSubmitting(true);
    return onSubmit({
      payload: { email, signUpCode },
      event,
    }).finally(() => setIsSubmitting(false));
  };

  return (
    <EmailInputWithContinueButton onSubmit={onSubmitHandler} {...props}>
      {signUpCodeConfig && (
        <FormControlLabel
          control={
            <Checkbox
              checked={hasSignUpCode}
              disabled={!allowSignUpCodeChange}
              onChange={(event) => setHasSignUpCode(event.target.checked)}
            />
          }
          label="I have an invite code from my service provider"
        />
      )}
      {hasSignUpCode && (
        <TextField
          fullWidth
          variant="outlined"
          size="medium"
          type="text"
          label="Invite Code"
          value={signUpCode}
          onChange={(e) => {
            const signUpCode = e.target.value as string;
            setSignUpCode(signUpCode);
            signUpCodeConfig?.onSignUpCodeChange?.(signUpCode);
          }}
          sx={{
            marginTop: tokens.spacingLg,
          }}
          disabled={isSubmitting || !allowSignUpCodeChange}
        />
      )}
    </EmailInputWithContinueButton>
  );
}
