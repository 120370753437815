import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material/styles';
import tokens from '@verifime/design-tokens';
import { formatToDDMMMYYYYHHMMAAA } from '@verifime/utils';
import { enqueErrorNotification } from '../../Notification';
import PortraitDisplay, { TPortraitDisplayProps } from '../PortraitDisplay';
import { ColoredIcon } from '../../ColoredIconAndChip';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';

export type TlivenessViewerProps = {
  checkedDateTime?: string;
  generateVideoDownloadUrl?: () => Promise<string | null>;
} & Pick<TPortraitDisplayProps, 'portraitImageUrl' | 'isShowRequestButton' | 'onRequest'>;

function CheckedDateTimeDisplay({
  checkedDateTime,
}: Pick<TlivenessViewerProps, 'checkedDateTime'>) {
  return (
    <>
      {checkedDateTime && (
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography variant="body2" color="textSecondary">
            Completed on
          </Typography>
          <Typography variant="body2">{formatToDDMMMYYYYHHMMAAA(checkedDateTime)}</Typography>
        </Stack>
      )}
    </>
  );
}

export default function LivenessViewer({
  generateVideoDownloadUrl,
  ...props
}: Readonly<TlivenessViewerProps>) {
  const videoClickHandler = async () => {
    if (generateVideoDownloadUrl) {
      const videoUrl = await generateVideoDownloadUrl();
      if (videoUrl) {
        window.open(videoUrl, '_blank');
      } else {
        enqueErrorNotification("Can't generate video download url");
      }
    }
  };

  return (
    <PortraitDisplay
      {...props}
      footer={
        <Stack gap={tokens.spacingXs} py={tokens.spacingXs} alignItems="center">
          <CheckedDateTimeDisplay {...props} />
          <Link href="#" onClick={videoClickHandler}>
            <Stack
              direction="row"
              gap={tokens.spacing2xs}
              justifyItems="center"
              alignItems="center"
            >
              Play Video
              <ColoredIcon iconName="openInNewRounded" />
            </Stack>
          </Link>
        </Stack>
      }
    />
  );
}
