import React, { useState } from 'react';
import { Button, Stack } from '@mui/material';
import { CircularProgressButton, TFilesUploadStatus, useDocumentUploader } from '@verifime/components';
import tokens from '@verifime/design-tokens';
import { TDocumentType } from '@verifime/api-definition';
import { DocumentType } from '@verifime/utils';
import { DocumentUploaderServiceCallsProps } from '../MultiFileUploader/useDocumentUploader';

interface UploadForVerificationProps {
    entityId: string;
    documentType: TDocumentType;
    isSubmittingForVerification: boolean;
    onClose: () => void;
    onSubmit: (fileId: string) => void;
    documentUploadServiceCalls: DocumentUploaderServiceCallsProps;
}

export default function UploadForVerification({
    entityId,
    documentType,
    isSubmittingForVerification,
    onClose,
    onSubmit,
    documentUploadServiceCalls,
}: UploadForVerificationProps) {
    const [enableSubmitButton, setEnableSubmitButton] = useState(false);
    const [fileId, setFileId] = useState<string | null>(null);

    const completeUpload = (res: TFilesUploadStatus) => {
        setFileId(Object.keys(res)[0]);
        setEnableSubmitButton(true);
    }

    const { renderedComponent } = useDocumentUploader({
        entityId: entityId,
        documentType: documentType as DocumentType,
        label: "Click to upload",
        isMultiple: false,
        isDraggable: true,
        ignoreExistingFiles: true,
        onAllUploadsDone: completeUpload,
        onFileDeleted: () => { setEnableSubmitButton(false) },
        api: documentUploadServiceCalls,
    });

    return (
        <>
            <Stack
                gap={tokens.spacingBase}
                alignItems="center"
                minHeight={tokens.size2xs}
                padding={tokens.spacingSm}>
                {renderedComponent}
            </Stack>
            <Stack
                flexDirection="row"
                justifyContent="flex-end"
                alignItems="center"
                gap={tokens.spacingLg}
            >
                <Button variant="outlined" onClick={onClose}>
                    Cancel
                </Button>
                <CircularProgressButton
                    variant="contained"
                    onClick={() => onSubmit(fileId)}
                    isShowProgress={isSubmittingForVerification}
                    disabled={!enableSubmitButton}
                >
                    Submit for Verification
                </CircularProgressButton>
            </Stack>
        </>
    );
}