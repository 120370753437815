import { useState } from 'react';
import LivenessPanel, { TLivenessPanelProps } from './Liveness/LivenessPanel';
import Stack from '@mui/material/Stack';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import tokens from '@verifime/design-tokens';
import IdentityDocumentPortrait, {
  TIdentityDocumentPortraitProps,
} from './IdentityDocumentPortrait';

enum PortraitType {
  IDENTITY_DOCUMENT = 'identityDocument',
  LIVENESS = 'liveness',
}

export type TPortraitPanelProps = {
  livenessPanelProps: TLivenessPanelProps;
  identityDocumentPortraitProps: TIdentityDocumentPortraitProps;
};

export default function PortraitPanel(props: TPortraitPanelProps) {
  const [portraitType, setPortraitType] = useState<PortraitType>(PortraitType.LIVENESS);

  const handlePortraitTypeChange = (
    _: React.MouseEvent<HTMLElement>,
    newPortraitType: PortraitType,
  ) => {
    setPortraitType(newPortraitType);
  };

  return (
    <Stack
      direction="column"
      alignItems="center"
      minWidth={tokens.size2xs}
      maxWidth={tokens.size2xs}
      padding={tokens.spacingXs}
      gap={tokens.spacingXs}
    >
      <ToggleButtonGroup
        exclusive
        value={portraitType}
        onChange={handlePortraitTypeChange}
        color="primary"
      >
        <ToggleButton value={PortraitType.LIVENESS} size="small">
          Face Check
        </ToggleButton>
        <ToggleButton value={PortraitType.IDENTITY_DOCUMENT} size="small">
          ID Portrait
        </ToggleButton>
      </ToggleButtonGroup>
      {portraitType === PortraitType.LIVENESS && <LivenessPanel {...props.livenessPanelProps} />}
      {portraitType === PortraitType.IDENTITY_DOCUMENT && (
        <IdentityDocumentPortrait {...props.identityDocumentPortraitProps} />
      )}
    </Stack>
  );
}
