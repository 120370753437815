import { useState } from 'react';
import { Typography, IconButton, useTheme, Box, Drawer, DrawerProps, Stack } from '@mui/material';
import tokens from '@verifime/design-tokens';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { useHideDocumentScrollbar } from '@verifime/components';
import { TSupportDocumentSubType } from '@verifime/api-definition';
import React from 'react';
import { DocumentUploaderServiceCallsProps } from '../MultiFileUploader/useDocumentUploader';
import { toDocumentType } from '@verifime/utils';
import UploadForVerification from './UploadForVerification';

export default function UploadDocumentDrawer({
  title,
  entityId,
  submitForVerification,
  documentUploadServiceCalls,
  supportDocumentType,
  open,
  onClose,
  onBack,
  anchor = 'bottom',
  ...otherProps
}: {
  entityId: string;
  supportDocumentType: TSupportDocumentSubType;
  onBack?: () => void;
  submitForVerification: (
    fileId: string,
    setIsSubmittingForVerification: (value: boolean) => void
  ) => Promise<void>;
  documentUploadServiceCalls: DocumentUploaderServiceCallsProps;
} & DrawerProps) {
  const theme = useTheme();
  useHideDocumentScrollbar(open);

  const [isSubmittingForVerification, setIsSubmittingForVerification] = useState(false);

  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor={anchor}
      transitionDuration={{
        enter: theme.transitions.duration.complex,
        exit: theme.transitions.duration.leavingScreen,
      }}
      {...otherProps}
    >
      <Box sx={{ maxHeight: '70vh', padding: tokens.spacingBase }}>
        <Stack justifyContent="space-between" flexDirection="row">
          <Stack flexDirection="row" alignItems="center">
            {onBack && <IconButton onClick={onBack}>
              <KeyboardArrowLeftIcon />
            </IconButton>}
            <Typography variant="h4">{title}</Typography>
          </Stack>
          <IconButton onClick={() => onClose('bottom', 'backdropClick')}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <UploadForVerification
          entityId={entityId}
          documentType={toDocumentType(supportDocumentType)}
          isSubmittingForVerification={isSubmittingForVerification}
          onClose={() => onClose('bottom', 'backdropClick')}
          onSubmit={(fileId) => submitForVerification(fileId, setIsSubmittingForVerification)}
          documentUploadServiceCalls={documentUploadServiceCalls} />
      </Box>
    </Drawer>
  );
}