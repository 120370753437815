export type TFeatureToggle = {};

export enum FeatureToggle {
  On = 'on',
  Off = 'off',
}

export function useFeatureToggle(): TFeatureToggle {
  return {};
}
