'use client';

import { usePasswordless, useLocalUserCache } from './hooks/usePasswordless';
import {
  PasswordlessLogin as PasswordlessComponent,
  SignInState,
  SignInMethodUsed,
  NonSSRWrapper,
} from '@verifime/components';
import React, { useEffect, useState } from 'react';

export default function PasswordlessLogin({
  children,
  signInRedirect,
  getMegicLinkRedirectUri,
  onSignInAsDifferentUser,
  initialEmail,
}: {
  children: React.ReactNode;
  signInRedirect: () => void;
  getMegicLinkRedirectUri?: () => Promise<string>;
  onSignInAsDifferentUser?: () => void;
  initialEmail?: string;
}) {
  const [signInState, setSignInState] = useState<SignInState>(SignInState.NOT_SIGNED_IN);
  const [loginError, setLoginError] = useState<Error | undefined>(undefined);
  const {
    requestSignInLink,
    authenticateWithFido2,
    signInStatus,
    signingInStatus,
    tokens,
    lastError,
  } = usePasswordless();
  const { lastSignedInUsers } = useLocalUserCache();
  const { email, credentials } = lastSignedInUsers?.at(0) ?? {};

  useEffect(() => {
    setLoginError(lastError);

    if (signingInStatus === 'SIGNIN_LINK_REQUESTED') {
      setSignInState(SignInState.SIGNIN_LINK_REQUESTED);
      return;
    }

    if (signInStatus === 'CHECKING' || signInStatus === 'REFRESHING_SIGN_IN') {
      setSignInState(SignInState.VALIDATE_SIGN_IN_STATUS);
      return;
    }

    if (signingInStatus === 'SIGNING_IN_WITH_LINK') {
      setSignInState(SignInState.VALIDATE_SIGN_IN_STATUS);
      return;
    }

    if (signingInStatus === 'SIGNING_OUT') {
      setSignInState(SignInState.NOT_SIGNED_IN);
      return;
    }

    if (signInStatus === 'SIGNED_IN' && tokens) {
      setSignInState(SignInState.SIGNED_IN);
      return;
    }

    if (signingInStatus === 'SIGNIN_LINK_EXPIRED') {
      setSignInState(SignInState.NOT_SIGNED_IN);
      setLoginError(new Error('Unable to sign-in with this link'));
      return;
    }

    if (
      signingInStatus === 'STARTING_SIGN_IN_WITH_FIDO2' ||
      signingInStatus === 'COMPLETING_SIGN_IN_WITH_FIDO2'
    ) {
      setSignInState(SignInState.VALIDATE_SIGN_IN_STATUS);
      return;
    }
    setSignInState(SignInState.NOT_SIGNED_IN);
  }, [signInStatus, signingInStatus, tokens, lastSignedInUsers, lastError]);

  const performSignIn = async (
    email: string,
    signInMethod: SignInMethodUsed,
    oneTimePassword: string = '',
  ) => {
    const magicLinkRedirectUri = await getMegicLinkRedirectUri?.();
    return new Promise<void>((resolve) => {
      if (signInMethod === SignInMethodUsed.MAGIC_LINK && typeof requestSignInLink === 'function') {
        requestSignInLink({
          username: email,
          oneTimePassword,
          redirectUri: magicLinkRedirectUri,
        })
          .signInLinkRequested.then(() => {
            resolve();
            setTimeout(() => {
              setSignInState(SignInState.SIGNIN_LINK_REQUESTED);
            }, 0);
          })
          .catch((err) => {
            setLoginError(err);
            console.warn(err);
            resolve();
          });
      } else if (
        signInMethod === SignInMethodUsed.FIDO &&
        typeof authenticateWithFido2 === 'function'
      ) {
        authenticateWithFido2({ username: email, credentials })
          .then(() => {
            resolve();
            setTimeout(() => {
              setSignInState(SignInState.VALIDATE_SIGN_IN_STATUS);
            }, 0);
          })
          .catch((err) => {
            setLoginError(err);
            console.warn(err);
            resolve();
          });
      }
    });
  };

  return (
    <NonSSRWrapper>
      <PasswordlessComponent
        performSignIn={performSignIn}
        signInState={signInState}
        lastSignedInEmail={initialEmail ?? email ?? ''}
        signInRedirect={signInRedirect}
        errorToShow={loginError}
        onSignInAsDifferentUser={onSignInAsDifferentUser}
      >
        {children}
      </PasswordlessComponent>
    </NonSSRWrapper>
  );
}
