'use client';

import React from 'react';
import { LoginContainer } from './common/LoginContainer';
import { SignInState } from './constants';
import MainLogin, { TMainLoginProps } from './MainLogin';
import { LoginToVerifiMeTitle } from './common/LoginTitle';
import ValidatingSignInStatus from './ValidatingSignInStatus';
import { SigningYouIn } from './SigningYouIn';
import PasswordlessLoginContextProvider from './PasswordlessLoginContextProvider';
import SignInLinkRequested from './SignInLinkRequested';
import Typography from '@mui/material/Typography';
import Link from 'next/link';

export default function PasswordlessLogin({
  ...props
}: {
  children?: React.ReactNode;
  signInState: SignInState;
  signInRedirect?: () => void;
} & TMainLoginProps) {
  const { signInState, signInRedirect } = props;

  if (signInState === SignInState.SIGNED_IN) {
    if (typeof signInRedirect === 'function') {
      signInRedirect();
    }
  }

  return (
    <PasswordlessLoginContextProvider lastSignedInEmail={props.lastSignedInEmail}>
      <LoginContainer>
        {SignInState.NOT_SIGNED_IN === signInState && (
          <>
            <LoginToVerifiMeTitle />
            <MainLogin {...props} />
            <Typography variant="body1">
              New to VerifiMe? <Link href="/sign-up">Create a wallet</Link>
            </Typography>
          </>
        )}
        {SignInState.SIGNIN_LINK_REQUESTED === signInState && (
          <>
            <LoginToVerifiMeTitle />
            <SignInLinkRequested />
          </>
        )}
        {SignInState.VALIDATE_SIGN_IN_STATUS === signInState && (
          <>
            <LoginToVerifiMeTitle />
            <ValidatingSignInStatus />
          </>
        )}
        {SignInState.SIGNING_YOU_IN === signInState && (
          <>
            <LoginToVerifiMeTitle />
            <SigningYouIn />
          </>
        )}
        {SignInState.SIGNED_IN === signInState && props.children && (
          <>
            <LoginToVerifiMeTitle />
            {props.children}
          </>
        )}
      </LoginContainer>
    </PasswordlessLoginContextProvider>
  );
}
